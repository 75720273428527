<template>
    <v-dialog v-model="dialog" scrollable persistent max-width="500px" transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-btn color="#0B3F67" dark v-on="on">
                Agregar
                <v-icon right>mdi-plus-box-outline</v-icon>
            </v-btn>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar Iniciativa Ecolones" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12" class="pb-0" v-if="anioConsulta >2021">
                        <v-select
                            :items="opciones"
                            v-model="iniciativa.opcion"
                            item-value="id"
                            item-text="opcion"
                            :error-messages="opcionErrors"             
                            @change="$v.iniciativa.opcion.$touch()"
                            @blur="$v.iniciativa.opcion.$touch()"
                            required                            
                            label="Tipo de Iniciativa"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" v-if="!isCertificacion">
                        <v-text-field 
                            v-model="iniciativa.centroAcopio"
                            label="Nombre Centro de Acopio"
                            :error-messages="centroAcopioErrors"
                            @input="$v.iniciativa.centroAcopio.$touch()"
                            @blur="$v.iniciativa.centroAcopio.$touch()"
                            counter="100"
                            required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="!isCertificacion">
                        <v-text-field
                            label="Tipo de Residuo"
                            v-model="iniciativa.tipoResiduo"
                            :error-messages="tipoResiduoErrors"             
                            @change="$v.iniciativa.tipoResiduo.$touch()"
                            @blur="$v.iniciativa.tipoResiduo.$touch()"
                            counter="100"
                            required>
                        </v-text-field>  
                    </v-col>
                    <v-col cols="12" v-if="!isCertificacion">
                        <v-text-field
                            label="Kilogramos Entregados"
                            v-model="iniciativa.kilos"
                            :error-messages="kilosErrors"             
                            @change="$v.iniciativa.kilos.$touch()"
                            @blur="$v.iniciativa.kilos.$touch()"
                            required>
                        </v-text-field>  
                    </v-col>
                    <v-col cols="12" v-if="!isCertificacion">
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="fechaMoment"
                                    label="Fecha"
                                    prepend-inner-icon="mdi-calendar-month"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="fecha" 
                                locale="es-CR" 
                                @input="menu = false">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12">
                        <v-file-input 
                            label="Evidencias"
                            accept=".png, .jpeg, .jpg"
                            v-model="evidencias"
                            :error-messages="evidenciasErrors"      
                            multiple
                            show-size                                
                            clearable>
                        </v-file-input>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :disabled="loading" color="error" @click="cancelar">Cancelar</v-btn>
                <v-btn text :loading="loading" color="#0B3F67" @click="agregarEstrella">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from "moment"
import axios from "@/axios";
import { required, requiredIf, decimal, maxLength } from "vuelidate/lib/validators";
import ModalTitle from '@/components/utils/ModalTitleComponent'
const maxFileSize = (files) => {
    if(files != null) {
        let maximo = files.length * (1024 * 1024);
        let total = 0;
        files.forEach(file => {
            total += file.size
        });        
        return total < maximo;
    }
    return false;
}

export default {
    created() {
        axios.defaults.headers.common["Authorization"] = "Bearer " + this.$store.getters.token;
    },
    components: {
      ModalTitle
    },
    computed: {
        fileSize() {
            return this.evidencias != null ? this.evidencias.length : 0;
        },
        idInstalacion() {
            return this.$store.getters.sucursal.id;
        },
        idUsuario() {
            return this.$store.getters.usuario.id;
        },
        fechaMoment () {
            return this.fecha ? moment(this.fecha).format('DD/MM/YYYY') : '';
        },
        centroAcopioErrors() {
            const errors = []
            if (!this.$v.iniciativa.centroAcopio.$dirty) return errors            
            !this.$v.iniciativa.centroAcopio.required && errors.push('El nombre del centro de acopio es requerido.')
            !this.$v.iniciativa.centroAcopio.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        tipoResiduoErrors() {
            const errors = []
            if (!this.$v.iniciativa.tipoResiduo.$dirty) return errors            
            !this.$v.iniciativa.tipoResiduo.required && errors.push('El tipo de residuo es requerido.')
            !this.$v.iniciativa.tipoResiduo.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        kilosErrors() {
            const errors = []
            if (!this.$v.iniciativa.kilos.$dirty) return errors            
            !this.$v.iniciativa.kilos.required && errors.push('Los kilogramos entregados son requeridos.')
            !this.$v.iniciativa.kilos.decimal && errors.push('Solo debe digitar números.')
            return errors
        },
        evidenciasErrors () {
            const errors = []
            if (!this.$v.evidencias.$dirty) return errors            
            !this.$v.evidencias.required && errors.push('La evidencia es requerida.')
            !this.$v.evidencias.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + 'MB.')        
            return errors
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },

        isCertificacion(){
            return this.iniciativa.opcion === "Certificación de Ecoins" ? true : false;
        },

        opcionErrors(){
            const errors = []
            if (!this.$v.iniciativa.opcion.$dirty) return errors
            !this.$v.iniciativa.opcion.required && errors.push('Campo requerido')
            return errors 
        }
    },
    data: () => ({
        dialog: false,
        loading: false,
        menu: false,
        iniciativa: {
            centroAcopio: '',
            tipoResiduo: '',
            kilos: '',
            opcion: '',
        },
        fecha: new Date().toISOString().substr(0, 10),
        evidencias: [],
/*         evidenciaOtros: null,
        evidCertificacion: [], */
        opciones:[
            {id: 1, opcion: "Apertura centros de acopio permanentes" },
            {id: 2, opcion: "Campañas de recuperación de residuos valorizables" },
            {id: 3, opcion: "Entrega de Materiales en centros de acopio" }
        ],
    }),
    validations: {
        iniciativa: {
            centroAcopio: { required: requiredIf(function(){
                return !this.isCertificacion;
            }), maxLength: maxLength(100) },
            tipoResiduo: { required: requiredIf(function(){
                return !this.isCertificacion;
            }), maxLength: maxLength(100) },
            kilos: { required: requiredIf(function(){
                return !this.isCertificacion;
            }), decimal },
            opcion: { required }
        },
        evidencias: { required, maxFileSize },
    },
    methods: {
        cancelar() {
            this.iniciativa = {};
            this.fecha = new Date().toISOString().substr(0, 10);
            this.evidencias = [];
/*             this.evidenciaOtros = [];
            this.evidCertificacion = []; */
            this.dialog = false;
            this.loading = false;
            this.$v.iniciativa.$reset();
            this.$v.evidencias.$reset();
        },
        agregarEstrella() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loading = true;
                let formData = new FormData();
                this.evidencias.forEach(file => {
                    formData.append('archivos', file);
                });
/*                 this.evidenciaOtros.forEach(file2 => {
                    formData.append('evidenciaOtros', file2);
                });
                this.evidCertificacion.forEach(file3 => {
                    formData.append('evidCertificacion', file3);
                }); */
                formData.append("fecha", this.fecha);
                formData.append("idInstalacion", this.idInstalacion);
                formData.append("idUsuario", this.idUsuario);
                formData.append("opcion", this.iniciativa.opcion);
                formData.append("centroAcopio", this.iniciativa.centroAcopio != undefined ? this.iniciativa.centroAcopio : "");
                formData.append("tipoResiduo", this.iniciativa.tipoResiduo != undefined ? this.iniciativa.tipoResiduo : "");
                formData.append("kilos", this.iniciativa.kilos != undefined ? this.iniciativa.kilos : 0);
                axios.post("/estrellas/agregaIniciativaEcolones", formData)
                .then(response => {
                    this.$emit("recargaTabla");
                    this.$store.dispatch("noti", { mensaje: "Iniciativa agregada", color: "success" });
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$store.dispatch("noti", { 
                          mensaje: "Ocurrió un error no se agregó la iniciativa",
                          color: "error"
                      });
                      this.cancelar();
                    }
                  }
                });
            }
        }
    }
};
</script>
